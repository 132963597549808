import React from "react";

const VideoBox = () => {
    return (
        <>
            <div className="video-box">
                <div className="close-video">
                    <i className="fa fa-times"></i>
                </div>
            </div>
        </>
    );
};
export default VideoBox;
